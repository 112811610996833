import React, { useState, useEffect } from "react";

import AOS from 'aos';
import Logo from "../../../assets/logo_test.png";
import Ask from "../../../assets/ask.png"
import { Button } from "react-bootstrap";
export default function Navbar(props) {
    const [scrolling, setScrolling] = useState(false);
    const { handleShow } = props;
    useEffect(() => {
        AOS.init({
          duration: 500, // Animation duration
          easing: 'ease-in-out', // Easing option
          once: true, // Whether animation should happen only once
        });
      }, []);
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      const handleScroll = () => {
        if (window.scrollY > 100) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
  return (
    <div
      data-aos="fade-up"
      className={`py-4 fixed relative transition-all duration-500 ${
        scrolling ? "bg-[#0e0e0e]" : "bg-transparent"
      }`}
    >
      <nav className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          {/* Company Logo */}
          <img src={Logo} alt="Company Logo" className="h-12 w-12" />
          {/* Company Name */}
          <span className="text-white font-custom text-xl  sm:text-4xl font-bold ">
            Kastélykereső
          </span>
        </div>

        <div className="flex  ">
         
          
          <button variant="link"  ><p className="text-white font-custom text-md  sm:text-4xl m-0 font-bold no-underline hover:text-red-500" onClick={() => { handleShow() }} >Rólunk</p></button>
         
         
        </div>
      </nav>
    </div>
  );
}
