import React from 'react';

const Footer = () => {
  return (
    <footer className="absolute bottom-0 w-full z-10 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center h-16">
          <p className="font-custom text-white opacity-100">Copyright © 2024 KastélyKereső</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;