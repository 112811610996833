
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore, collection,getDocs
} from "firebase/firestore";
import {getStorage} from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBs_WTzJ4dNcGmaSqbIG0hVvwAh59lMQJw",
  authDomain: "geolocator-d0ce5.firebaseapp.com",
  projectId: "geolocator-d0ce5",
  storageBucket: "geolocator-d0ce5.appspot.com",
  messagingSenderId: "922259222373",
  appId: "1:922259222373:web:09e3980f3bd93361cd7b8c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore()
export const storage = getStorage(app)
export const auth = getAuth(app);
const colRef = collection(db,"Castles2")

getDocs(colRef)
  .then((snapshot) =>{
    let castles = []
    snapshot.docs.forEach((doc) =>{
      castles.push({...doc.data(),id:doc.id})
    })
    
  })
  .catch(error =>{
    console.log(error)
  })
  