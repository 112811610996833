
import React from 'react'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import Castle from "./assets/paris.png"
import './particles.css';
const ParticlesComponent = () => {

    const particlesInit = useCallback(async engine => {
        
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

  return (
    <Particles 
    
    id ="tsparticles"
    init={particlesInit}
    Loaded={particlesLoaded}
    
    

    options={{
        background: {
          color: "#181A18"
        },
        fullScreen: {
            enable: true, // enabling this will make the canvas fill the entire screen, it's enabled by default
            zIndex: -1, // this is the z-index value used when the fullScreen is enabled, it's 0 by default
          },
        fpsLimit: 60,
        fullscreen: true,
        interactivity: {
          detectsOn: "canvas",
          events: {
            resize: true
          }
        },
        
        particles: {
          color: {
            value: "#ff934d"
          },
          move: {
            direction: "none",
            enable: true,
            outModes: "out",
            random: false,
            speed: 1,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 1000
            },
            limit: 0,
            value: 100
          },
          opacity: {
            animation: {
              enable: true,
              minimumValue: 0.05,
              speed: 1,
              sync: false
            },
            random: {
              enable: true,
              minimumValue: 0.05
            },
            value: 0.8
          },
          shape: {
            type: "image",
            
            color: "#ff934d",
            
            image: {
              src: Castle
                
            }
          },
          size: {
            value: { min: 20, max: 30 }
          }
        }
      }}
    />
  )
}

export default ParticlesComponent

