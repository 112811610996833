
import React,{useContext} from 'react'
import { Route, Routes,Navigate } from 'react-router-dom'
import App from './App';
import WelcomeScreen from "./WelcomeScreen.js"
import { userContext } from "./SetData.js"
import {  onAuthStateChanged } from "firebase/auth";

const Main = () => {
    const [user, setUser] = useContext(userContext)
    return (
        <Routes>
            <Route path="/" element={<WelcomeScreen/>}></Route>
            <Route path="/Home" element={user ? <App /> : <Navigate to="/" />}></Route>
            
            
        </Routes>
    )
}

export default Main

