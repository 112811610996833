import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import reportWebVitals from './reportWebVitals';
import { SkinProvider } from "./SetData.js"
import { BrowserRouter } from "react-router-dom"
import Main from './Main.js'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} >
      <SkinProvider>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </SkinProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
