import Carousel from 'react-bootstrap/Carousel';
import './App.css';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import L from "leaflet";
import PopupComponent from "./PopupComponent";
import Button from 'react-bootstrap/Button';
import { HiOutlineXMark } from "react-icons/hi2";
import { db, storage } from "./firebase-config";
import { collection, getDocs, updateDoc, doc  } from "firebase/firestore"
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './NavbarComponent';
import ListGroup from 'react-bootstrap/ListGroup';
import { FcPhone, FcGlobe, FcLibrary, FcCheckmark, FcCancel, FcSettings, FcCameraAddon, FcPlus, FcEngineering, FcCameraIdentification, FcBinoculars } from "react-icons/fc";
import ModalComponent from './ModalComponent';
import SettingsModal from './SettingsModal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ViewImagesModal from "./ViewImagesModal"
import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import Nophoto from "./assets/nophoto.png"
import DeletePhotosModal from "./DeletePhotosModal"
import { imagesContext, castlesContext, duplicateContext, zvalueContext } from "./SetData.js"
import { useContext } from "react"
import { useMap } from 'react-leaflet/hooks'




function App() {

  function createIcon(url,size) {
    return new L.Icon({
      iconUrl: require(`${url}`),
      iconSize: [size, size],
      iconAnchor: [2, 2],
    });
  }

  function GetIcon(name, visit) {

    if (name.toLowerCase().includes("vár") && visit == true) {
      return createIcon("./assets/castle.png",50);
      
    } else if (name.toLowerCase().includes("kastély") && visit == true) {
      return createIcon("./assets/tower.png",50);
      
    } else {
      return createIcon("./assets/reddot.png",15);
      
    }
  }
  var castleicon = new L.Icon({
    iconUrl: require("./assets/castle.png"),
    iconSize: [50, 50],
    iconAnchor: [2, 2],
  });
  var towericon = new L.Icon({
    iconUrl: require("./assets/tower.png"),
    iconSize: [50, 50],
    iconAnchor: [2, 2],
  });
  var reddoticon = new L.Icon({
    iconUrl: require("./assets/reddot.png"),
    iconSize: [15, 15],
    iconAnchor: [2, 2],
  });

  const [mapcenter, setMapCenter] = useState([47.155144406049075, 19.377390506866522])
  const [show, setShow] = React.useState(false)
  const [dataarray, setDataArray] = useContext(castlesContext)
  const [image, setImage] = useState("")
  const [name, setName] = useState("")
  const [adress, setAdress] = useState("")
  const [telephone, setTelephone] = useState("")
  const [visited, setVisited] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [settingsmodalShow, setSettingsModalShow] = React.useState(false)
  const [castleid, setCastleId] = useState(null)
  const [specificimages, setSpecificImages] = useContext(imagesContext)
  const [showdeletemodal, setShowDeleteModal] = React.useState(false)
  const [imagemodalshow, setImageModalShow] = React.useState(false)
  const [filteredarray, setFilteredArray] = useContext(duplicateContext)
  const [zvalue, setZvalue] = useContext(zvalueContext)
  
  

  const handleClose = () => setSettingsModalShow(false);
  const handleModalClose = () => setModalShow(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleViewImageModalShowClose = () => setImageModalShow(false);
  const castleCollectionRef = collection(db, 'Castles')

  

  const jumpToSelectedLocation = (prop) => {
    dataarray.map((item) => {

      if (item.castlename === prop) {
        setShow(true)
        setSpecificImages([])
        setImage(item.url)
        setAdress(item.adress)
        setName(item.castlename)
        setTelephone(item.telnumber == null ? "Nincs Még Megadva" : item.telnumber)
        setVisited(item.visited)
        setCastleId(item.docid)
        getSpecificImages(item.docid)
      }

    })
  }

  const updateVisit = async () => {
    const item = doc(db, "Castles", castleid);
    await updateDoc(item, {
      visited: !visited
    });
    dataarray.map((item) => {

      if (item.castlename === name) {
        item.visited = !visited
      }

    })
  
    setVisited(!visited)
    setSettingsModalShow(false)


  }


  const getSpecificImages = (castleid) => {

    let listreference = (ref(storage, `${castleid}/`))
    listAll(listreference)
      .then((res) => {

        res.items.forEach((itemRef) => {
          let newobject = {}
          newobject.path = itemRef._location.path

          getDownloadURL(itemRef).then((url) => {
            newobject.url = url
            setSpecificImages((prev) => [...prev, newobject])
          });


        });
      }).catch((error) => {
        console.log(error)
      });
  }

  const uploadPhoto = (prop) => {

    if (prop == []) {
      alert("Nem választottál filet, válassz és próbáld újra")
    }
    else {
      Array.from(prop).forEach((file) => {
        const imageRef = ref(storage, `${castleid}/${file.name}`);
        uploadBytes(imageRef, file).then(() => {

          setSpecificImages((prev) => [...prev, file])


        });
      })
    }
    setShow(false)



  }
  useEffect(() => {
    setDataArray(dataarray)
  }, [dataarray]);

  useEffect(() => {


    const getCastles = async () => {
      getDocs(castleCollectionRef)
        .then((snapshot) => {

          let newarray = []
          snapshot.docs.forEach((doc) => {
            var subdata = doc.data()


            subdata.docid = doc.id
            newarray.push({ ...subdata })
          })
          setDataArray(newarray)
          setFilteredArray(newarray)

        })

        .catch(error => {
          console.log(error)
        })
    }
    getCastles();

  }, [])

  function hidePanel() {
    setZvalue(-1000)
    setShow(false)

  }

  function FlyMapTo() {

    const map = useMap()


    useEffect(() => {
      dataarray.map((item) => {

        if (item.castlename === name) {

          map.flyTo([item.position1, item.position2], map.getZoom())
        }

      })

    }, [])

    return null
  }




  return (
    <div style={{ height: '100vh', display: 'flex', width: '100%', flexDirection: 'column' }}>
      <div style={{ width: '100%' }}>
        <NavbarComponent jumptoSelectedLoc={jumpToSelectedLocation} closeSelectedLoc={hidePanel} />
      </div>
      <Offcanvas backdrop={false} scroll={true} show={show} onHide={handleClose} style={{ width: "500px" }}>
        <Offcanvas.Header style={{ padding: "0px" }} >
        
          <div style={{ height: "300px", width: "100%", backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "flex-end" }} >

            <Button style={{ borderRadius: "35px", backgroundColor: "rgba(255, 255, 255, .7)",padding:"19px", width: "70px", height: "70px", marginTop: "20px", marginRight: "20px", borderColor: "transparent" }} onClick={() => hidePanel()}><HiOutlineXMark size={30} style={{ color: "black" }} /></Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ width: "100%", backgroundColor: "white", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            <ListGroup className="list-group-flush" style={{ width: "100%" }}>
              <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}><FcLibrary size={30} style={{ marginRight: "10px" }} />    <p style={{ fontWeight: "bold", marginTop: "15px" }}>{name}</p></ListGroup.Item>
              <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}><FcGlobe size={30} style={{ marginRight: "10px" }} />    <p style={{ marginTop: "15px" }}>{adress}</p></ListGroup.Item>
              <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}><FcPhone size={30} style={{ marginRight: "10px" }} />    <p style={{ letterSpacing: "1px", marginTop: "15px" }}>{telephone}</p></ListGroup.Item>


              {visited ?
                <React.Fragment>
                  <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <FcCheckmark size={30} style={{ marginRight: "10px" }} />
                      <p style={{ fontSize: "20px", marginTop: "15px" }}>Meg Lett Már Látogatva</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>


                    </div>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <FcEngineering size={30} style={{ marginRight: "10px" }} />
                      <p style={{ fontSize: "20px", marginTop: "15px" }}>Látogatási Státusz Megváltosztatása</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>

                      <Button variant="outline-secondary" onClick={() => setSettingsModalShow(true)} ><FcSettings size={30} /></Button>
                      <SettingsModal
                        show={settingsmodalShow}
                        onClose={handleClose}
                        changeVisit={updateVisit}
                      />
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <FcPlus size={30} style={{ marginRight: "10px" }} />
                      <p style={{ fontSize: "20px", marginTop: "15px" }}>Kép feltöltése</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button variant="outline-secondary" onClick={() => setModalShow(true)} ><FcCameraAddon size={30} /></Button>
                      <ModalComponent
                        show={modalShow}
                        onClose={handleModalClose}
                        uploadPhotos={uploadPhoto}
                      />

                    </div>
                  </ListGroup.Item>

                  {visited && specificimages.length != 0 ?
                    <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <FcCancel size={30} style={{ marginRight: "10px" }} />
                        <p style={{ fontSize: "20px", marginTop: "15px" }}>Kép Törlése</p>
                      </div>
                      <Button variant="outline-secondary" onClick={() => setShowDeleteModal(true)}><FcCameraIdentification size={30} /></Button>
                      <DeletePhotosModal
                        show={showdeletemodal}
                        onClose={handleDeleteModalClose}
                        photolist={specificimages}

                      />

                    </ListGroup.Item> :
                    <></>

                  }
                  <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "0px" }}>

                    <p style={{ marginLeft: "5px", marginTop: "10px", }}>Feltöltött Képek:</p>
                  </ListGroup.Item>
                </React.Fragment>

                :

                <ListGroup.Item style={{ height: "80px", fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FcCancel size={30} style={{ marginRight: "10px" }} />
                    <p style={{ fontSize: "20px", marginTop: "15px" }}>Nem lett Még Meglátogatva</p>
                  </div>
                  <Button variant="outline-secondary" onClick={() => setSettingsModalShow(true)}><FcSettings size={30} /></Button>
                  <SettingsModal
                    show={settingsmodalShow}
                    onClose={handleClose}
                    changeVisit={updateVisit}
                  />

                </ListGroup.Item>
              }

            </ListGroup>
            {specificimages.length == 0 ?
              <div style={{ width: "100%", height: "300px", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column" }}>

                <img

                  src={Nophoto}
                  alt="slide"
                  style={{ borderRadius: "20px", height: "100%", backgroundRepeat: "no-repeat", width: "75%" }}
                />

                <p style={{}}>Nincsenek Még Képek feltöltve</p>
              </div> :

              <Carousel style={{ marginTop: "15px", marginBottom: "80px" }}>

                {specificimages.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={item.url}
                        alt="Zárd be a panelt és nyisd meg újra ha nem tölt be a kép"
                        style={{ borderRadius: "20px", height: "300px", imageSize: "cover", backgroundRepeat: "no-repeat", objectFit: "cover" }}
                      />

                    </Carousel.Item>
                  )

                })}

              </Carousel>

            }

            <ViewImagesModal
              show={imagemodalshow}
              onClose={handleViewImageModalShowClose}
              photolist={specificimages}
            />


          </div>
          <div style={{ width: "100%" }}>

            <Button variant="secondary" onClick={() => setImageModalShow(true)} style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >Képek Teljes Képernyős Megtekintése<FcBinoculars size={30} /></Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div style={{ height: "100vh", width: "100%", display: "flex", flexDirection: "row" }} >


        <MapContainer center={mapcenter} zoom={8} scrollWheelZoom={true} style={{ width: "100%", height: '100%' }}  >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {dataarray.map((item, index) => {

            return (
              <Marker key={index} index={index} position={[item.position1, item.position2]} icon={GetIcon(item.castlename, item.visited)} eventHandlers={{
                click: () => {

                  setShow(true)
                  setSpecificImages([])
                  setImage(item.url)
                  setAdress(item.adress)
                  setName(item.castlename)
                  setTelephone(item.telnumber == null ? "Nincs Még Megadva" : item.telnumber)
                  setVisited(item.visited)
                  setCastleId(item.docid)
                  getSpecificImages(item.docid)
                  


                },
              }}>
                <FlyMapTo position1={item.position1} position2={item.position2} />


                <Tooltip><PopupComponent url={item.url} telnumber={item.telnumber} location={item.adress} castlename={item.castlename} ></PopupComponent></Tooltip>

              </Marker>
            )
          })}



        </MapContainer>
      </div>
    </div>


  );
}

export default App;
