import React, {  useRef, useEffect,useContext } from 'react';
import Form from 'react-bootstrap/Form';
import './searchbar.css';
import Castle from "./assets/castle.png"
import Tower from "./assets/tower.png"
import NoresultGif from "./assets/noresults.gif"
import { zvalueContext } from "./SetData.js"
const SearchbarDropdown = (props) => {
  const { options, onInputChange, zindvalue, handleClick, inputRef, handleKeyDown } = props;
  const ulRef = useRef();

  const [zvalue, setZvalue] = useContext(zvalueContext)

  useEffect(() => {
    inputRef.current.addEventListener('click', (event) => {
      event.stopPropagation();
      
      onInputChange(event);
    });
    
    
  }, []);
  return (
    <div className="search-bar-dropdown">
      <Form.Control
        id="search-bar"
        type="text"
        className="form-control"
        placeholder="keresés"
        ref={inputRef}
        onChange={onInputChange}
        style={{ height: '100%' }}
        onKeyDown={handleKeyDown}
      />
      <ul id="results" className="list-group" ref={ulRef} style={{ zIndex: zindvalue }} >

        {options.length == 0 ?

          <div ref={ulRef} style={{ zIndex: zindvalue,justifyContent:"space-between",alignItems: "center",display: "flex",flexDirection:"column", backgroundColor:"white",height:"20em"}} >
            <img

              src={NoresultGif}
              alt="slide"
              style={{  backgroundRepeat: "no-repeat", width:"170px",height:"170px",marginTop:"30px" }}
            />
            <p  style={{fontSize:"20px",fontFamily:"Montserrat",marginBottom:"30px",textAlign:"center"}} >{"Hoppá, erre nincs találat :("}</p>
          </div> :
          options.map((option, index) => {

            return (
              <button
                type="button"
                key={index}
                onClick={() => { handleClick(option.castlename); inputRef.current.value = option.castlename;setZvalue(-1000) }}
                className="list-group-item list-group-item-action result-item"
                style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'space-between' }}
              >
                <p style={{ marginBottom: "0px",fontFamily:"Montserrat",fontWeight:"Light" }}>{option.castlename}</p>
                <img src={option.castlename.toLowerCase().includes("vár") ? Tower : Castle} alt="slide" style={{ borderRadius: "20px", height: "30px", backgroundRepeat: "no-repeat" }} />
              </button>)
          }
          )

        }
      </ul>
    </div>
  );
};





export default SearchbarDropdown;