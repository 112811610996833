

import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import "./Carousel.css"

const SettingsModal = props => {


    const { show, onClose, photolist } = props;

    return (
        <Modal
            show={show}
            onHide={onClose}
            fullscreen={true}
            
        >
            <Modal.Header closeButton>
                <Modal.Title  >
                    Teljes Képernyős Nézet
                </Modal.Title>

            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column",height: "100%",display:"flex",justifyContent: "center",alignItems: "center",width: "100%"}}>
                <Carousel style={{ marginTop:"15px",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",width: "100%"}}>

                    {photolist.map((item, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <img
                                    className="d-block carousel-img "
                                    src={item.url}
                                    alt="slide"
                                    style={{ borderRadius: "20px",  imageSize: "cover", backgroundRepeat: "no-repeat",marginTop:"50px" }}
                                />

                            </Carousel.Item>
                        )

                    })}

                </Carousel>



            </Modal.Body>
            <Modal.Footer>


                <Button variant="secondary" onClick={onClose}>Teljes Képernyős Nézt Kikapcsolása</Button>
            </Modal.Footer>
        </Modal>
    );
}



export default SettingsModal

