
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";


const ModalComponent = props => {
  const { show, onClose,uploadPhotos } = props;

  const [file, setFile] = useState('')
  


  
  const handleChange = (e) => {
    setFile(e.target.files)

    
    
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Válassz Ki egy fájlt amit fel akarsz tölteni
        </Modal.Title>

      </Modal.Header>
      <Modal.Body style={{ height: "200px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <div style={{ width: "100%", height: "50px", dislay: "flex", alignItems: "center" }}>
          Kattints a lenti gombra hogy kiválassz egy vagy több képet
          </div>
        <input type="file" accept="image/png, image/jpeg" multiple onChange={handleChange} style={{ marginTop: "80px" }} />


      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" onClick={() => uploadPhotos(file)}>Kép Feltöltése</Button>
        <Button variant="secondary" onClick={onClose}>Becsukás</Button>
      </Modal.Footer>
    </Modal>
  );
}



export default ModalComponent

