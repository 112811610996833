

import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



const SettingsModal = props => {

  
  const { show, onClose,changeVisit } = props;
  
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Át akarod állítani az állapotát ennek a kastélynak? Ezt megváltoztathatod majd utána.
        </Modal.Title>

      </Modal.Header>
      <Modal.Body style={{ height: "200px", display: "flex", flexDirection: "column" }}>
        <p>Ha már meglátogattad ezt a kastélyt, akkor nyomd meg a gombot, hogy feltölthess képeket. </p>



      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" onClick={changeVisit}>Igen megváltoztatom</Button>
        <Button variant="secondary" onClick={onClose}>Meggondoltam Magam</Button>
      </Modal.Footer>
    </Modal>
  );
}



export default SettingsModal

