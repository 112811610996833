import React, { useEffect,useState,useContext } from "react";
import { auth } from "../../../firebase-config.js";
import LandingPageVid from "../../../assets/landing_page_video.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
import { userContext } from "../../../SetData.js";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { Toast } from "react-bootstrap";
import Loader from "./Loader.js"


export default function App() {
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const toggleShowA = () => setShowA(!showA);
  const [user, setUser] = useContext(userContext);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showA, setShowA] = useState(false);
  const navigate = useNavigate();
  const handleNavigateClick = () => navigate("/Home");
  const handleNavigateClick2 = () => navigate("/");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        handleNavigateClick();
      } else {
        handleNavigateClick2();
      }
    });
  }, []);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        handleNavigateClick();
      } catch (error) {
        if (error) {
          setShowA(true);
        }
      }
    }
  };

  const loginUser = async () => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      handleNavigateClick();
    } catch (error) {
      if (error) {
        console.log(error);
        setShowA(true);
      }
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing option
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="relative overflow-hidden w-screen h-screen">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={LandingPageVid} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {loading && <Loader/>}
      {/* Dark Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>

      <div className="relative  h-full px-4 py-28 md:py-40 w-screen lg:px-8 z-10 flex items-center">
        <section class=" mx-auto py-16">
          <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            
            <div class="w-full bg-white bg-opacity-25 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div class="p-6 space-y-4 md:space-y-6 sm:p-8 sm:w-96 w-72 ">
                <h1 class="text-xl font-bold font-custom leading-tight text-center tracking-tight text-white md:text-2xl dark:text-white">
                  Belépés
                </h1>
                <form class="space-y-4 md:space-y-6" >
                  <div>
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-white font-custom dark:text-white"
                    >
                      Email cím
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      name="email"
                      id="email"
                      class="  border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@company.com"
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-sm font-medium text-white font-custom dark:text-white"
                    >
                      jelszó
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      id="password"
                      placeholder="••••••••"
                      class=" border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                    />
                  </div>
                  <div style={{ width: "100%", display: "flex", marginTop: "15px" }}>
                  <Toast show={showA} onClose={toggleShowA} bg='danger'>
                    <Toast.Header>
                      <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                      />
                      <strong className="me-auto">Hiba</strong>

                    </Toast.Header>
                    <Toast.Body>Rossz Email-cím vagy jelszó </Toast.Body>
                  </Toast>
                </div>
                  <button
                   onClick={(e) => {e.preventDefault(); loginUser() }}

                    class="w-full text-black font-custom bg-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Belépés
                  </button>
                 
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
