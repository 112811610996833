import { createContext, useState, useEffect } from "react";
import { auth } from "./firebase-config";
import { onAuthStateChanged } from "firebase/auth";

export const imagesContext = createContext()
export const castlesContext = createContext()
export const duplicateContext = createContext()
export const userContext = createContext()
export const zvalueContext = createContext()

export const SkinProvider = (props) => {
    const [specificimages, setSpecificImages] = useState([])
    const [dataarray, setDataArray] = useState([])
    const [filteredarray, setFilteredArray] = useState([])
    const [user, setUser] = useState(null)
    const [zvalue, setZvalue] = useState(-1000)


    



    return (

        <imagesContext.Provider value={[specificimages, setSpecificImages]}>
            <castlesContext.Provider value={[dataarray, setDataArray]}>
                <duplicateContext.Provider value={[filteredarray, setFilteredArray]}>
                    <userContext.Provider value={[user, setUser]}>
                        <zvalueContext.Provider value={[zvalue, setZvalue]} >
                            {props.children}
                        </zvalueContext.Provider>
                    </userContext.Provider>
                </duplicateContext.Provider>
            </castlesContext.Provider>
        </imagesContext.Provider>



    )
}