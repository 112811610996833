import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./Navbar.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useContext, useRef } from 'react'
import { castlesContext, duplicateContext, zvalueContext } from "./SetData.js"
import Tower from "./assets/tower.png"
import Castle from "./assets/castle.png"
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { FiXCircle } from "react-icons/fi";
import All from "./assets/all.png"
import Logout from "./assets/logout.png"
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import Logo from "./assets/logo_test.png"
import Location from "./assets/location.png"

import AddLocationModal from './AddLocationModal';
import SearchbarDropdown from './SearchBar';


const NavbarComponent = props => {
  const { jumptoSelectedLoc, closeSelectedLoc } = props;
  var stringSimilarity = require("string-similarity");

  const [modalshow, setModalShow] = useState(false)
  const [options, setOptions] = useState([]);
  const [zvalue, setZvalue] = useContext(zvalueContext)
  const [formvalue, setFormValue] = useState("")
  const [dataarray, setDataArray] = useContext(castlesContext)
  const [filteredarray, setFilteredArray] = useContext(duplicateContext)
  const [filter, setFilter] = useState("Nincs")
  const inputRefVal = useRef();
  const auth = getAuth();

  const onInputChange = (event) => {
    setOptions(
      dataarray.filter((option) => option.castlename.toLowerCase().includes(event.target.value))

    );
    setFormValue(event.target.value);
    if (event.target.value !== "") {
      setZvalue(100000000)
    } else {
      setZvalue(-100000000)
    }




  };

  const navigate = useNavigate();
  const handleNavigateClick = () => navigate('/');
  const handleClose = () => setModalShow(false);



  const resetFilter = () => {
    setDataArray(filteredarray)
    setFilter("Nincs")
    inputRefVal.current.value = ""
    setZvalue(-1000)
    closeSelectedLoc()
  }



  const filterSearchedCastle = (prop) => {




    setDataArray(dataarray.filter(word => word.castlename.toLowerCase().includes(prop.toLowerCase())))
    setFilter(prop)
    jumptoSelectedLoc(prop)

  }
  const filterSearchedCastles = () => {
    var names = dataarray.map(a => a.castlename.toLowerCase())

    var matches = stringSimilarity.findBestMatch(formvalue.toLocaleLowerCase(), names);
    let best_match = dataarray[matches.bestMatchIndex].castlename
    setDataArray(dataarray.filter(word => word.castlename.toLowerCase().includes(best_match.toLowerCase())))
    setFilter(best_match)
    jumptoSelectedLoc(best_match)

  }
  const handleEnterPress = (e) => {

    if (e.key === 'Enter') {
      e.preventDefault()
      filterSearchedCastles()
    } 
  }


  const filterKastélyok = () => {
    setFilter("Kastélyok")
    setDataArray(dataarray.filter(word => word.castlename.toLowerCase().includes("kastély")))
  }
  const filterVárak = () => {
    setFilter("Várak")
    setDataArray(dataarray.filter(word => word.castlename.toLowerCase().includes("vár")))
  }
  const filterVisited = () => {
    setFilter("Meglátogatottak")



    setDataArray(dataarray.filter(word => word.visited === true))

  }
  const filterNotVisited = () => {
    setFilter("Nem Meglátogatottak")
    setDataArray(dataarray.filter(word => word.visited === false))
  }
  const signUserOut = () => {
    signOut(auth).then(() => {
      
      handleNavigateClick()
    }).catch((error) => {
      console.log(error)
    });

  }





  return (
    <>

      <Navbar bg="light" expand={'lg'} style={{ maxHeight: "100px", width: "100%" }}>
        <Container fluid>
          <Navbar.Brand ><img src={Logo} alt="slide" style={{ borderRadius: "20px", height: "50px", width: "50px", backgroundRepeat: "no-repeat", marginLeft: "2px" }} /></Navbar.Brand>
          <Navbar.Brand style={{ fontSize: "12px", fontFamily: 'Montserrat', fontWeight: "900", color: "black", textAlign: "center", letterSpacing: "3px" }}>
            KastélyKereső
          </Navbar.Brand>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                Menü
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Form className="d-flex">

                  <Button variant="outline-success" onClick={() => { setModalShow(true) }} style={{ height: "100%", borderColor: "transparent", borderRadius: "100px", marginRight: "10px", display: "flex" }}> <Navbar.Text className='text-sm' style={{ marginRight: "20px" }}>Hely Hozzáadása</Navbar.Text><img src={Location} alt="slide" style={{ borderRadius: "20px", height: "40px", width: "px", backgroundRepeat: "no-repeat" }} /></Button>
                </Form>

                <Form className="d-flex" >
                  <Button variant="outline-success" style={{ height: "100%", borderRadius: "500px", borderRadius: "100px", marginRight: "10px", display: "flex", alignItems: "center" }}> <img src={Logout} alt="slide" style={{  height: "30px", width: "30px", backgroundRepeat: "no-repeat" }} onClick={() => { signUserOut() }} /></Button>
                </Form>
                <AddLocationModal
                  show={modalshow}
                  onClose={handleClose}

                />
                <Form className="d-flex" style={{ alignItems: "center", marginLeft: "10px" }}>
                  <Navbar.Text className='text-sm' style={{ marginRight: "20px" }}>Jelenlegi Szűrés:</Navbar.Text>
                  <Navbar.Text className='text-sm' style={{ fontWeight: "bold", marginRight: "10px" }}> {filter}</Navbar.Text> {filter == "Nincs" ? <></> : <Form className="d-flex" style={{ marginRight: "20px" }}>
                    <Button variant="outline-success" onClick={() => { resetFilter() }} style={{ backgroundColor: "transparent", borderColor: "transparent" }}><FiXCircle size={25} /></Button></Form>}</Form>

                <NavDropdown
                  title="Szűrés"
                  className='text-sm'
                  id={`offcanvasNavbarDropdown-expand-lg`}
                  style={{ display: "flex", alignItems: "center", textDecoration: "underline", fontWeight: "bold", fontSize: "13px", marginLeft: "10px" }}
                >
                  <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent', width: "100%", textAlign: 'left', display: "flex", flexDirection: "row" }} onClick={() => { filterVisited() }} disabled={filter == "Nincs" ? false : true} > <NavDropdown.Item style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>Meglátogatott Helyek <FcCheckmark size={30} style={{ marginLeft: "15px" }} /></NavDropdown.Item> </Button>

                  <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent', width: "100%", textAlign: 'left', display: "flex", flexDirection: "row" }} onClick={() => { filterNotVisited() }} disabled={filter == "Nincs" ? false : true}><NavDropdown.Item style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >Nem Meglátogatott Helyek  <FcCancel size={30} style={{ marginLeft: "15px" }} /> </NavDropdown.Item></Button>
                  <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent', width: "100%", textAlign: 'left', display: "flex", flexDirection: "row" }} onClick={() => { filterKastélyok() }} disabled={filter == "Nincs" ? false : true} ><NavDropdown.Item style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >Kastélyok <img src={Castle} alt="slide" style={{ borderRadius: "20px", height: "30px", width: "30px", backgroundRepeat: "no-repeat", marginLeft: "15px" }} /></NavDropdown.Item> </Button>
                  <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent', width: "100%", textAlign: 'left', display: "flex", flexDirection: "row" }} onClick={() => { filterVárak() }} disabled={filter == "Nincs" ? false : true} ><NavDropdown.Item style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >Várak <img src={Tower} alt="slide" style={{ borderRadius: "20px", height: "30px", width: "30px", backgroundRepeat: "no-repeat", marginLeft: "15px" }} /> </NavDropdown.Item> </Button>

                  <NavDropdown.Divider></NavDropdown.Divider>
                  <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent', width: "100%", textAlign: 'left', display: "flex", flexDirection: "row" }} onClick={() => { resetFilter() }}><NavDropdown.Item style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >Minden Mutatatása <img src={All} alt="slide" style={{ borderRadius: "20px", height: "30px", width: "30px", backgroundRepeat: "no-repeat", marginLeft: "15px" }} /> </NavDropdown.Item> </Button>
                </NavDropdown>
              </Nav>

              <Form className="d-flex">

                <SearchbarDropdown options={options} onInputChange={onInputChange} zindvalue={zvalue} handleClick={filterSearchedCastle} inputRef={inputRefVal} handleKeyDown={handleEnterPress} />

                <Button variant="outline-success" style={{ height: "100%", marginRight: "30px" }} onClick={() => { filterSearchedCastles(formvalue) }}>Keresés</Button>

              </Form>

            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
}


export default NavbarComponent

