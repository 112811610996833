
import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState,useContext } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { imagesContext } from "./SetData.js"


import { getStorage, ref,deleteObject  } from "firebase/storage";

const InnerModal = props => {
  const { show, onClose, url } = props;
  const [specificimages, setSpecificImages] = useContext(imagesContext)

  

  const deletePhoto = () => {
    const storage = getStorage();
    let deleteurl = { url }
    
    const desertRef = ref(storage, deleteurl.url);
    
    deleteObject(desertRef).then(() => {
      setSpecificImages(specificimages.filter(item => item.path !== deleteurl.url))
      
    }).catch((error) => {
      console.log(error)
    });
    
    onClose()
    
    
  }

return (
  <Modal
    show={show}
    onHide={onClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop={false}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Ez törölni fogja a választott képet.
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <p>
        Csak akkor folytasd ha biztos vagy abban hogy végleg törölni szeretnéd a képet
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => { deletePhoto() }}>Igen, törölni akarom</Button>
      <Button variant="secondary" onClick={props.onClose}>Mégse</Button>
    </Modal.Footer>
  </Modal>
);
}



const ModalComponent = props => {
  const { show, onClose, photolist } = props;
  const [modalShow, setModalShow] = useState(false);
  const [path,setPath] = useState(null);
  const handleClose = () => setModalShow(false);



  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          Válaszd ki azt a képet amellyet törölni szeretnél
        </Modal.Title>

      </Modal.Header>
      <Modal.Body style={{ height: "350px", display: "flex", flexDirection: "column", justifyContent: "center", overflow: "hidden", overflowY: "scroll", width: "100%" }}>
        <Container >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            {photolist.map((item, index) => {
              return (

                <Col key={index} xs="auto" style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <Button style={{ backgroundColor: "transparent", borderRadius: "20px", padding: "0px" }} onClick={() => { setModalShow(true);setPath(item.path) }}>
                    <img

                      src={item.url}
                      alt="slide"
                      style={{ borderRadius: "20px", height: "160px", backgroundRepeat: "no-repeat", width: "260px", objectFit: "cover", borderColor: "green", borderWidth: "1px" }}
                    />

                  </Button>
                  <InnerModal
                    show={modalShow}
                    onClose={handleClose}
                    url={path}
                    
                  >

                  </InnerModal>
                </Col>

              )
            })}
          </Row>
        </Container>


      </Modal.Body>
      <Modal.Footer>


        <Button variant="secondary" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}



export default ModalComponent

