import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./Navbar.css";
import { useState} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import "./welcomescreen.css";
import Navbar from "./views/WelcomeScreen/components/Navbar.js";
import TopSections from "./views/WelcomeScreen/components/TopSection.js";
import Clipboard from "./assets/user-gear.png"
import PhotoGallery from "./assets/add-image.png"
import NoMoney from "./assets/coins.png"
import Footer from "./views/WelcomeScreen/components/Footer.js";

const WelcomeScreen = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <main className="flex flex-col items-center justify-center ">
        <div className=" fixed top-0 w-full z-20">
          <Navbar handleShow={handleShow}></Navbar>
        </div>
        <TopSections></TopSections>
        <Footer />
      </main>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Mi az a KastélyKereső?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'flex-start', marginBottom: '30px', MarginTop: '60px', marginLeft: "20px" }}> <img src={Clipboard} alt="slide" style={{  height: "40px", width: "40px", backgroundRepeat: "no-repeat",marginRight:"30px" }} onClick={() => { handleShow() }} /> <ListGroup.Item className="font-custom text-sm"  style={{borderWidth:"0px"}}>Tartsd Számon Melyik Kastélyokban voltál eddig</ListGroup.Item></div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'flex-start', marginBottom: '30px', MarginTop: '30px', marginLeft: "20px" }}> <img src={PhotoGallery} alt="slide" style={{ height: "40px", width: "40px", backgroundRepeat: "no-repeat" ,marginRight:"30px"}} onClick={() => { handleShow() }} /><ListGroup.Item className="font-custom text-sm"  style={{borderWidth:"0px"}}>Tölts fel képeket mindegyikhez</ListGroup.Item></div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'flex-start', marginBottom: '30px', MarginTop: '30px', marginLeft: "20px" }}> <img src={NoMoney} alt="slide" style={{ height: "40px", width: "40px", backgroundRepeat: "no-repeat" ,marginRight:"30px"}} onClick={() => { handleShow() }} /> <ListGroup.Item className="font-custom text-sm"  style={{borderWidth:"0px"}}>Teljesen Ingyenes</ListGroup.Item></div>

          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Becsukás
          </Button>

        </Modal.Footer>
     </Modal>
    </>
  );
};

export default WelcomeScreen;
