
import React from 'react'
import { FiMapPin, FiPhone } from "react-icons/fi";

import './Popup.css';
const PopupComponent = (props) => {
    return (
        <div style={{ width: "300px", height: "100%",padding:"10px",borderRadius:"20px" }}>
            <img
                src={props.url}
                alt="new"
                style={{ width: "100%", backgroundSize: 'cover', borderRadius: "10px" }}
            />
            <p style={{ fontWeight: 'bold', marginBottom: "10px", marginTop: "10px", marginLeft: "10px", fontSize: "17px" }}>{props.castlename.length >= 28?  props.castlename.slice(0, 28) + "...":props.castlename}</p>

            <div className="infobox" >
                
                <div className="endbox">
                <div className="reviewbox3">
                    <FiMapPin size={30} style={{ marginLeft: "7px" }} />
                    <p style={{ fontWeight: 'bold', fontSize: "13px", marginLeft: "20px",marginTop:"16px" }}>{props.location.length >= 30?  props.location.slice(0, 30) + "...":props.location }</p>
                </div>
                <div className="reviewbox3" >
                    <FiPhone size={30} style={{ marginLeft: "7px" }} />
                    <p style={{ fontWeight: 'bold', fontSize: "13px", marginLeft: "20px",marginTop:"18px" }}>{props.telnumber == null ? "Nincs Még Megadva" : props.telnumber}</p>
                </div>
                </div>

            </div>
        </div>
    )
}

export default PopupComponent

