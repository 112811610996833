
import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react'
import { collection, addDoc } from "firebase/firestore"; 
import { db } from "./firebase-config";
import { useContext } from "react"
import { castlesContext} from "./SetData.js"

const AddLocationModal = props => {
    const { show, onClose } = props;
    const [castlename, setCastleName] = useState("")
    const [file, setFile] = useState("")
    const [longitude, setLongitude] = useState("")
    const [altitude, setAltitude] = useState("")
    const [adress, setAdress] = useState("")
    const [telnumber, setTelnumber] = useState(null)
    const [dataarray, setDataArray] = useContext(castlesContext)

    const handleChange = (e) => {
        setFile(e.target.value)
        console.log(e.target.files[0])
      }

    
    const handleNameChange = (event) => { setCastleName(event.target.value) }
    const handleLongitudeChange = (event) => { setLongitude(event.target.value) }
    const handleAltitudeChange = (event) => { setAltitude(event.target.value) }
    const handleAdressChange = (event) => { setAdress(event.target.value) }
    const handleTelnumberChange = (event) => { setTelnumber(event.target.value) }

    const addLocation = async () => {
        let highestindex = Math.max(...dataarray.map(o => o.id));
        

        await addDoc(collection(db, "Castles"), {
            castlename: castlename,
            adress: adress,  
            id: highestindex + 1,
            position1: longitude,
            position2: altitude,
            telnumber: telnumber,
            url: file,
            visited: false
          });
          onClose()
          
        
    }
    
    return (
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "20px" }}>
                    Szeretnél egy új kastélyt vagy várat hozzáadni? Töltsd ki az adatokat és nyomj a hozzáadás gombra
                </Modal.Title>

            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
                <Form>
                    <Form.Group className="mb-3" >
                        <Form.Label>Kastélynév</Form.Label>
                        <Form.Control value={castlename} onChange={handleNameChange} placeholder="Kastélynév" />

                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Szélesség</Form.Label>
                        <Form.Control value={longitude} onChange={handleLongitudeChange} placeholder="Szélesség" />
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Hosszuság</Form.Label>
                        <Form.Control value={altitude} onChange={handleAltitudeChange} placeholder="Hosszúság" />

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Cím</Form.Label>
                        <Form.Control value={adress} onChange={handleAdressChange} placeholder="KastélyCím" />

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Telefonszám</Form.Label>
                        <Form.Control value={telnumber} onChange={handleTelnumberChange} placeholder="Telefonszám (üresen hagyható)" />

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Kép URL</Form.Label>
                        <Form.Control value={file} onChange={handleChange} placeholder="A kastély képének az URL-je" />

                    </Form.Group>


                </Form>



            </Modal.Body>
            <Modal.Footer>

                <Button variant="primary" onClick={addLocation}>Hozzáadás</Button>
                <Button variant="secondary" onClick={onClose}>Meggondoltam Magam</Button>
            </Modal.Footer>
        </Modal>
    );
}





export default AddLocationModal

